<template>
    <div class="noticeBack" @click="closeModal()">
        <div class="notice" @click.stop>
            <img src="@/assets/icons/exit.svg" @click="closeModal()" class="exit">
            <div class="notice__top">
                <p class="notice__top__title">Центр уведомлений</p>
                <div class="notice__top__count">5</div>
            </div>
            <div class="line"></div>
            <div class="notice__block">
                <div class="notice__block__in" v-for="item in 4" :key="item">
                    <div class="notice__block__in__top">
                        <img src="@/assets/icons/person.svg"/>
                        <div class="notice__block__in__top__info">
                            <div class="notice__block__in__top__info__top">
                                <p class=notice__block__in__top__info__top__name>Кейт М.</p>
                                <p class=notice__block__in__top__info__top__status>добавлен(-а) в список клиентов</p>
                            </div>
                            <div class="notice__block__in__top__info__date">
                                Воскресенье, 17 Ноября 15:32
                            </div>
                        </div>
                    </div>
                    <div class="line">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    closeModal(){
        this.$emit('closeModal')
    }
  }
};
</script>
<style lang="scss" scoped>
.exit{
    filter: brightness(0) saturate(100%) invert(66%) sepia(8%) saturate(11%) hue-rotate(12deg) brightness(95%) contrast(84%);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
}
    .line{
        border-top: 1px solid #0000001A;
        width: 100%;
    }
    .notice{
        background-color: white;
        width: 35%;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 20px;
        border-radius: 20px;
        &__top{
            display: flex;
            gap: 15px;
            &__title{
                font-size: 24px;
                font-weight: 600;
                color: #11415D;
            }
            &__count{
                width: 30px;
                height: 30px;
                background: #F8F8F8;
                border: 1px solid #0000001A;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0000004D;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 25px;
            &__in{
                display: flex;
                flex-direction: column;
                gap: 25px;
                &__top{
                    display: flex;
                    gap: 20px;
                    img{
                        width: 60px;
                        height: 60px;
                    }
                    &__info{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: auto;
                        &__top{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            &__status{
                                color: #0000008A;
                            }
                            &__name{
                                font-weight: 600;
                            }
                        }
                        &__date{
                            color: #0000008A;
                        }
                    }
                }
            }
        }
    }
</style>