import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";  
import "swiper/css";
import "swiper/scss/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/grid";
import VueLeaflet from '@vdcrea/vue-leaflet'
import '@vdcrea/vue-leaflet/dist/vue-leaflet.css'
import { Vue3Toastify, toast } from 'vue3-toastify';

const lang = localStorage.getItem("lang") || "ru";
axios.defaults.baseURL = "https://admin.mentorme.kz/api/V1";
axios.defaults.params = {
  lang: lang,
};
createApp(App).use(store).use(router).use(Vue3Toastify, {
  autoClose: 3000,
}).use(VueLeaflet).use(VueViewer).mount("#app");
