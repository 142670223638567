<template>
    <div class="extendBack" @click="closeModal()">
        <div class="extend" @click.stop>
            <p class="extend__title">Продление договора</p>
            <p class="customText">Выберите способ оплаты и срок продления договора</p>
            <div class="line"></div>
            <div class="extend__tabs">
                <p class="customText">Срок продления</p>
                <div class="extend__tabs__block">
                    <div class="extend__tabs__block__in" v-for="item in tabs" :key="item.id" :class="{customTab: selectedTab == item.id}" @click="selectedTab = item.id"> 
                        <p class="customText">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="extend__method">
                <p class="customText">Способ оплаты</p>
                <div class="extend__method__block">
                    <div class="extend__method__block__in" @click="selectedMethod = 'card'" :class="{customTab: selectedMethod == 'card'}">
                        <img src="@/assets/icons/card.svg"/>
                        <p class="customText">Картой</p>
                    </div>
                    <div class="extend__method__block__in" @click="selectedMethod = 'kaspi'" :class="{customTab: selectedMethod == 'kaspi'}">
                        <img src="@/assets/icons/kaspi.svg"/>
                        <p class="customText">Kaspi QR</p>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <p class="customText">Отсканируйте QR-код для оплаты продления договора в приложении Kaspi.kz</p>
            <img src="@/assets/icons/kaspi.svg" class="extend__kaspi" v-if="selectedMethod == 'kaspi'"/>
            <button v-if="selectedMethod == 'kaspi'" @click="closeModal()">Готово</button>
            <button v-else>Продолжить</button>
        </div>
    </div>
</template>
<script>

export default {
  data() {
    return {
        selectedTab: 1,
        selectedMethod: 'kaspi',
        tabs: [
            {
                id: 1,
                name: '1 месяц'
            },
            {
                id: 2,
                name: '3 месяцев'
            },
            {
                id: 3,
                name: '6 месяцев'
            },
            {
                id: 4,
                name: '12 месяцев'
            },
        ]
    };
  },
  methods: {
    closeModal(){
        this.$emit('closeModal')
    }
  }
};
</script>
<style lang="scss" scoped>
    .customTab{
        background-color: #11415D;
        font-weight: 600;
        img{
            filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(6592%) hue-rotate(227deg) brightness(129%) contrast(100%) !important; 
        }
        p{
            
        color: white;
        }
    }
    .extendBack{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
    }
    img{
        width: 30px;
    }
    .customText{
        color: #0000008A;
        font-size: 18px;
    }
    .line{
        border-top: 1px solid #0000001A;
        width: 100%;
    }
    .extend{
        background-color: white;
        width: 35%;
        padding: 45px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
        border-radius: 20px;
        &__kaspi{
            width: 20%;
        }
        button{
            width: 40%;
            padding: 15px 0px;
            border-radius: 20px;
            background-color: #11415D;
            border: none;
            color: white;
        }
        &__title{
            font-size: 32px;
            color: #11415D;
            font-weight: 600;
        }
        &__tabs{
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: 100%;
            &__block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: 20px;
                &__in{
                    width: 33.333%;
                    padding: 15px 0px;
                    cursor: pointer;
                    border-right: 1px solid #0000001A;
                    &:first-child{
                        border-radius: 20px 0px 0px 20px;
                    }
                    &:last-child{
                        border-right: none;
                        border-radius: 0px 20px 20px 0px;
                    }
                }
            }
        }
        &__method{
            display: flex;
            flex-direction: column;
            gap: 25px;
            width: 100%;
            &__block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border: 1px solid #0000001A;
                border-radius: 20px;
                &__in{
                    width: 50%;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    justify-content: center;
                    padding: 15px 0px;
                    border-right: 1px solid #0000001A;
                    cursor: pointer;
                    &:first-child{
                        border-radius: 20px 0px 0px 20px;
                    }
                    &:last-child{
                        border-right: none;
                        border-radius: 0px 20px 20px 0px;
                    }
                    img{
                        width: 20px;
                        filter: brightness(0) saturate(100%) invert(77%) sepia(10%) saturate(0%) hue-rotate(141deg) brightness(84%) contrast(104%);
                    }
                }
            }
        }
    }
</style>