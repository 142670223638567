<template>
    <div class="useful">
        <div class="useful__left">
            <platformTable />
        </div>
        <div class="useful__right">
            <div class="useful__right__top">
                <div class="useful__right__top__tabs">
                    <!-- <div
                        class="useful__right__top__tabs__in"
                        v-for="(tab, index) in tabs"
                        :key="tab"
                        :class="{ customTab: selectedTab === index }"
                        @click="selectTab(index)"
                        ref="tabs"
                    >
                        {{ tab.name }}
                    </div> -->
                    <swiper 
                    class="useful__right__top__tabs__swiper"
                    @swiper="getSwiperCategory" 
                    :breakpoints="{
                        0: {
                            slidesPerView: 'auto'
                        }
                    }"
                    :spaceBetween="50"
                    :speed="500"
                    :lazy="true" 
                > 
                    <swiper-slide v-for="(tab, index) in tabs" 
                        :key="tab"
                        :class="{ customTab: selectedTab === index }"
                        @click="selectTab(index), isCommunity = false, openQuestion = false"
                        ref="tabs"> 
                        {{ tab.name }}
                    </swiper-slide> 
                </swiper>
                </div>
                <div class="useful__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="useful__right__block" v-if="selectedTab == 0">
                <div class="useful__right__block__in">
                    <p class="useful__right__block__in__title">Аудио-подкасты</p>
                    <div class="useful__right__block__in__bot">
                        <div class="useful__right__block__in__bot__in" v-for="item in 5">
                            <grantCard />
                        </div>
                    </div>
                </div>
                    <div class="useful__right__block__in">
                        <p class="useful__right__block__in__title">Видео-подкасты</p>
                        <div class="useful__right__block__in__bot">
                            <div class="useful__right__block__in__bot__in" v-for="item in 3">
                                <grantCard />
                            </div>
                        </div>
                    </div>
            </div>
            <button class="more" v-if="selectedTab == 0">Показать больше</button>
            <div class="rating" v-if="selectedTab == 1">
                <p class="customTitle">Рейтинги</p>
                <div class="rating__block">
                    <div class="rating__block__in" v-for="item in 4">
                        <div class="rating__block__in__top">
                            <img src="@/assets/icons/rating.svg"/>
                            <div class="rating__block__in__top__status">
                                Shanghai Ranking
                            </div>
                        </div>
                        <p class="rating__block__in__name">QS World University Rankings</p>
                        <button>Подробнее</button>
                    </div>
                </div>
            </div>
            <div class="community" v-if="selectedTab == 2 && isCommunity == false">
                <p class="customTitle">Community</p>
                <div class="community__block">
                    <div class="community__block__in" v-for="item in 5" :key="item" @click="isCommunity = true">
                        <img src="@/assets/icons/usa.svg"/>
                        <p>США</p>
                    </div>
                </div>
            </div>
            <div class="community" v-if="isCommunity">
                <p class="customTitle" @click="isCommunity = false">< <span>США</span></p>
                <div class="community__block">
                    <div class="community__block__in1" v-for="item in 5" :key="item" @click="isCommunity = true">
                        <p>Qazaqstan Graduates Association in the UK</p>
                    </div>
                </div>
            </div>
            <div class="gide" v-if="selectedTab == 3">
                <p class="customTitle">Гайды</p>
                <div class="gide__block">
                    <div class="gide__block__in" v-for="item in 4" :key="item">
                        <grantCard />
                    </div>
                </div>
            </div>
            <button class="more" v-if="selectedTab == 3">Показать больше</button>
            <div class="community" v-if="selectedTab == 4 && openQuestion == false">
                <p class="customTitle">Q&A</p>
                <div class="community__block">
                    <div class="community__block__in" v-for="item in 3" :key="item" @click="openQuestion = true">
                        <img src="@/assets/icons/qa.png"/>
                        <p>Бакалавриат</p>
                    </div>
                </div>
            </div>
            <div class="inQa" v-if="openQuestion">
                <div class="inQa__top">
                    <div class="inQa__top__left" @click="openQuestion = false">
                        <p><span>Q&A</span></p>
                    </div>
                    <div class="inQa__top__right">
                        <input type="search" placeholder="Поиск вопроса..."/>
                        <button>Поиск вопроса</button>
                    </div>
                </div>
                <div class="inQa__block">
                    <div class="inQa__block__in" v-for="(item, index) in 5" :key="item">
                        <div class="inQa__block__in__top" @click="selectQuestion(index)">
                            <div class="inQa__block__in__top__left">
                                <p class="inQa__block__in__top__left__question">Вопрос 1:</p>
                                <p>Когда была основана компания?</p>
                            </div>
                            <img src="@/assets/icons/redSelect.svg" v-if="selectedQuestion == index" style="rotate: 180deg"/> 
                            <img src="@/assets/icons/redSelect.svg" v-else/> 
                        </div>
                        <div class="inQa__block__in__bot" v-if="selectedQuestion == index">
                            Мы сотрудничаем с крупнейшими брендами и банками такими как ...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
import customSelect from '@/components/customSelect.vue';
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo, grantCard, customSelect
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: -1,
            selectedStep: -1,
            selectedTab: 1,
            isCommunity: false,
            openQuestion: false,
            swiperRefCategory: null,
            selectedQuestion: -1,
            swiperRef: null,
            activeSwiperIndex: 0,
            tabs: [
                {
                    name: 'Полезное'
                },
                {
                    name: 'Рейтинги'
                },
                {
                    name: 'Community'
                },
                {
                    name: 'Гайды'
                },
                {
                    name: 'Q&A'
                },
            ],
        }
    },
    methods: {
        selectTab(index) {
            this.selectedTab = index;
            this.swiperRefCategory?.slideTo(index, 500);
            this.scrollToTop();
        },
        getSwiperCategory(swiper) {
            this.swiperRefCategory = swiper;
            this.activeSwiperIndex = swiper.activeIndex;
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        selectQuestion(index){
            if(index == this.selectedQuestion){
                this.selectedQuestion = -1
            }
            else{
                this.selectedQuestion = index
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.more{
    padding: 15px 0px;
    width: 30%;
    margin: 0 auto;
    border-radius: 10px;
    border: none;
    background-color: #11415D;
    color: white;
}
.customTitle{
    font-size: 24px;
    color: #0000008A;
    font-weight: 600; 
    cursor: pointer;
}
.rating{
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__block{
        display: flex;
        flex-wrap: wrap;
        gap: 1.25%;
        &__in{
            width: 19%;
            padding: 10px;
            border-radius: 5px;
            background-color: white;
            display: flex;
            cursor: pointer;
            flex-direction: column;
            gap: 10px;
            &__top{
                width: 100%;
                position: relative;
                img{
                    width: 100%;
                    height: auto;
                }
                &__status{
                    position: absolute;
                    background-color: #FD2D2A;
                    color: white;
                    left: 10px;
                    top: 10px;
                    padding: 5px;
                    border-radius: 5px;
                }
            }
            &__name{
                font-weight: 700;
                text-align: center;
            }
            button{
                padding: 10px 0px;
                width: 100%;
                background-color: #FD2D2A;
                border: none;
                border-radius: 5px;
                color: white;
            }
        }
    }
}
.useful{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left{
        width: 20%;
    }
    &__right{
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: 36px;
                color: #00000026;
                display: flex;
                position: relative;
                &__swiper{
                    width: 100%;
                    .swiper-slide {
                        cursor: pointer;
                        width: fit-content;
                    }
                }
                .customTab {
                    color: #000; // Активный таб выделяется
                }
            }
            &__right{
                width: 25%;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 30px;
            &__in{
                display: flex;
                flex-direction: column;
                cursor: pointer;
                gap: 20px;
                &__title{
                    color: #0000008A;
                    font-size: 24px;
                }
                &__bot{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2.5%;
                    row-gap: 20px;
                    &__in{
                        width: 18%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.community{
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__block{
        display: flex;
        flex-wrap: wrap;
        gap: 3.5%;
        row-gap: 40px;
        &__in, &__in1{
            width: 31%;
            cursor: pointer;
            padding: 10px;
            background-color: white;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
        }
        &__in1{
            padding: 35px 0px;
            border: 1px solid #0000001A;
            box-shadow: 0px 4px 3px 1px #0000000D;
        }
    }
}
.gide{
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__block{
        display: flex;
        flex-wrap: wrap;
        gap: 2.5%;
        row-gap: 20px;
        &__in{
            width: 18%;
            cursor: pointer;
        }
    }
}
.inQa{
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__top{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        select, input{
            padding: 10px 15px;
            border-radius: 10px;
            color: #0000008A;   
            background-color: transparent;
            border: 1px solid #0000001A;
            cursor: pointer;
        }
        &__left{
            display: flex;
            gap: 20px;
            align-items: center;
            font-size: 24px;
            color: #0000008A;
            font-weight: 600;
        }
        &__right{
            width: 60%;
            display: flex;
            justify-content: space-between;
            input{
                width: 75%;
                font-style: italic;
            }
            button{
                width: 20%;
                background-color: #11415D;
                color: white;
                border: none;
                padding: 10px 15px;
                border-radius: 10px;
            }
        }
    }
    &__block{
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__in{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px 30px;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0px 8px 8px 2px #0000000D;
            cursor: pointer;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    width: 20px;
                }
                &__left{
                    display: flex;
                    gap: 10px;
                    font-weight: 700;
                    align-items: center;
                    &__question{
                        color: #0000008A;
                        font-weight: 400;
                    }
                }
            }
            &__bot{
                padding: 10px 20px;
                background-color: #F5F7FB;
                border: 1px solid #00000024;
                height: 150px;
                border-radius: 10px;
                font-weight: 500;
            }
        }
    }
}
</style>