<template>
    <div class="aboutBack" @click="closeModal">
        <div class="about" >
            <p class="about__title">Подробнее о курсе</p>
            <p class="about__sub">Выберите способ оплаты для покупки курса</p>
            <div class="line"></div>
            <p class="about__sub">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tincidunt velit vitae tellus aliquet, vel commodo purus tristique. Integer  feugiat vitae est at vehicula. 
                <br/><br/>
Quisque ut nunc cursus, rutrum diam  semper, bibendum nunc. Nulla sit amet purus magna. Maecenas eu velit  feugiat, viverra elit vel, aliquam eros. Fusce tempus dui tristique quam ultrices, nec mattis magna laoreet.</p>
            <button class="about__ready" @click="closeModal">Купить</button>
        </div> 
    </div>
</template>
<script>
export default {
    data(){
        return{
            isOk: false,
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
}
.aboutBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.about{
    width: 33%;
    background-color: white;
    padding: 40px 60px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &__title{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #11415D;
    }
    &__sub{
        color: #0000008A;
        text-align: center;
    }
    &__ready{
        margin-top: 20px;
        background-color: #11415D;
        color: white;
        border: none;
    }
    button{
        width: 48%;
        padding: 15px 0px;
        border-radius: 10px;
    }
    &__ok{
        width: 40%;
    }
}
</style>