import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";
import { createToaster } from "@meforma/vue-toaster";

export default createStore({
  state: {
    role: 'user',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
