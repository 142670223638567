<template>
    <div class="customSelect">
        <div class="customSelect__top" @click="openBot = !openBot" :class="{customTop: openBot == true, withoutBorder: isBorder}">
            <p>Выбор</p>
            <img src="@/assets/icons/select.svg"/>
        </div>
        <div class="customSelect__bot" v-if="openBot">
            <div class="customSelect__bot__in">
                <p>Вариант 1</p>
            </div>
            <div class="customSelect__bot__in">
                <p>Вариант 2</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['isBorder'],
    data(){
        return{
            openBot: false,
        }
    }
}
</script>
<style lang="scss" scoped>
.customTop{
    border-radius: 10px 10px 0px 0px;
}
.withoutBorder{
    border: none !important;
}
    .customSelect{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        &__top{
            padding: 15px 25px;
            width: 100%;
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            border-radius: 10px;
            border: 1px solid #0000001A;
        }
        &__bot{
            border: 1px solid #0000001A;
            width: 100%;
            border-radius: 10px;
            top: 100%;
            position: absolute;
            background-color: #F0F2F5;
            z-index: 999;
            &__in{
                &:last-child{
                    border-bottom: none
                }
                border-bottom: 1px solid #0000001A;
                color: #0000004D;
                padding: 15px 0px;
                border-radius: 10px;
                p{
                    padding: 0 25px;
                }
                transition: all 0.5s ease;
                &:hover{
                    background-color: #F8F8F8;
                    color: black;
                }
            }
        }
    }
</style>