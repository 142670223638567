<template>
    <talkCurator v-if="openCurator" @closeModal="openCurator = false"/>
    <div class="favorite">
        <div class="favorite__left">
            <platformTable />
        </div>
        <div class="favorite__right">
            <div class="favorite__right__top">
                <div class="favorite__right__top__left">
                    <div class="favorite__right__top__left__title">Избранное</div>
                    <button class="favorite__right__top__left__talk" @click="openCurator = true">
                        <img src="@/assets/icons/talk.svg"/>
                        <p>Обсуждение с куратором</p>
                    </button>
                </div>
                <div class="favorite__right__top__right">
                    <userInfo />
                </div>      
            </div>
            <div class="search">
                <div class="line"></div>
                <div class="search__top">
                    <div class="search__top__left">
                        <input type="search" placeholder="Поиск программ...."/>
                        <img src="@/assets/icons/find.svg"/>
                    </div>  
                    <div class="search__top__right">
                        <p>Сортировка:</p>
                       
                    <customSelect :is-border="true"/>
                    </div>
                </div>
                <div class="search__results">
                    <div class="search__results__in" v-for="item in 5">
                        <newCardComponent :is-favorite="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userInfo from '@/components/userInfo.vue';
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import talkCurator from '@/components/talkCurator.vue';
import customSelect from '@/components/customSelect.vue';
export default {
    components: {
        userInfo,  platformTable, newCardComponent, talkCurator, customSelect
    },
    data(){
        return{
            activeTab: 'list',
            openCurator: false
        }
    }
}
</script>
<style lang="scss" scoped>
.button-pulse {
  animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 white;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0); 
  }
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.activeTab{
    background-color: white !important;
}
    .favorite{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left{
                display: flex;
                gap: 30px;
                align-items: center;
                &__title{
                    font-size: 36px;
                    font-weight: 600;
                }
                &__talk{
                    display: flex;
                    gap: 10px;
                    transition: .5s all ease-in-out;
                    position: relative;
                    align-items: center;
                    padding: 15px 30px;
                    background-color: inherit;
                    border: 1px solid #11415D;
                    border-radius: 5px;
                    color: #11415D;
                    font-weight: 700;
                    cursor: pointer;
                    img{
                        width: 20px;
                    }
                }
            }
            &__right {
                width: 25%;
            }
        }
    }
    }
    .search{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__left{
            width: 63%;
            position: relative;
            input{
                width: 100%;
                padding: 15px 25px;
                border: 1px solid #0000001A;
                background-color: inherit;
                border-radius: 10px;
            }
            img{
                width: 20px;
                position: absolute;
                right: 15px;
                cursor: pointer;
            }
        }
        &__right{
            max-width: 30%;
            display: flex;
            cursor: pointer;
            gap: 5px;
            border-radius: 10px;
            align-items: center;
            gap: 10px;
            border: 1px solid #0000001A;
            padding: 0px 10px;
            p{
                color: #0000008A;
            }
            select{
                border: none;
                background-color: transparent;
            }
        }
    }
    &__results{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: 30px;
        &__in{
            width: 48%;
        }
    }
    }
</style>