<template>
    <div class="curatorBack" @click="closeModal">
        <div class="curator" >
            <p class="curator__title">Оценить услугу</p>
            <p class="curator__sub">Оцените услугу “Название услуги”</p>
            <div class="line"></div>
            <img src="@/assets/icons/reviewImage.svg" class="curator__ok"/>
            <p class="curator__text">Название услуги</p>
            <div class="curator__stars">
                <div class="curator__stars__in" v-for="item in 5">
                    <img src="@/assets/icons/star.svg"/>
                </div>
            </div>
            <button class="curator__ready" @click="closeModal">Оценить</button>
        </div> 
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.curatorBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.curator{
    width: 25%;
    background-color: white;
    padding: 20px 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    &__text{
        font-weight: 600;
    }
    &__title{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #11415D;
    }
    &__sub{
        color: #0000008A;
        text-align: center;
    }
    &__ready{
        margin-top: 20px;
        background-color: #11415D;
        color: white;
        border: none;
    }
    &__stars{
        display: flex;
        gap: 20px;
        align-items: center;
        img{
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
    button{
        width: 48%;
        padding: 15px 0px;
        border-radius: 10px;
    }
    &__ok{
        width: 30%;
    }
}
</style>