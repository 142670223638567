<template>
    <div class="admission">
        <div class="admission__left">
            <platformTable />
        </div>
        <div class="admission__right">
            <div class="admission__right__top">
                <div class="admission__right__top__left">
                   Поступление
                </div>
                <div class="admission__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="admission__right__swiper">
                <swiper :effect="'coverflow'" :grabCursor="true" :centeredSlides="true" 
                :loop="true"
                :coverflowEffect="{
                    rotate: 0,
                    stretch: 0,
                    depth: 150,
                    modifier: 5,
                    slideShadows: false
                }" 
                :breakpoints="{
                            640: { slidesPerView: 2.22 },
                        }">
                    <swiper-slide v-for="item in 10" :key="item" class="admission__right__swiper__slide">
                        <div class="cardBack">
                            <div class="card">
                                <div class="card__top">
                                    <div class="card__top__left">
                                        <div class="card__top__left__rank">
                                            <img src="@/assets/icons/crown.svg" />
                                            <p>1</p>
                                        </div>
                                        <div class="card__top__left__text">
                                            <p class="card__top__left__text__title">BOLASHAK</p>
                                            <p class="card__top__left__text__sub">Technology and Policy</p>
                                        </div>
                                    </div>
                                    <div class="card__top__right">
                                        <p><span>Deadline 1:</span> 12.12.2024</p>
                                        <div class="line3">
                                        </div>
                                        <p><span>Deadline 2:</span> 12.12.2025</p>
                                    </div>
                                </div>
                                <div class="card__bot">
                                    Massachusetts Institute of Technology
                                </div>
                            </div>
                            <div class="cardBack__bot" @click="$router.push('/testPlatformFind/1')"><p>Подробнее</p></div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="progress1">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>ПРОГРЕСС ПО ВУЗУ</p>
                    <div class="line"></div>
                </div>
                <div class="progress1__block">
                    <div class="progress1__block__in" v-for="item in progress" :key="item">
                        <div class="progress1__block__in__left">
                            <div class="progress1__block__in__left__img">
                                <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                            </div>  
                            <div class="progress1__block__in__left__info">
                                <p class="progress1__block__in__left__info__name" :class="{customText: item.status != 'Завершено'}">{{ item.name }}</p>
                                <p class="progress1__block__in__left__info__text" :class="{customText: item.status != 'Завершено'}">{{ item.text }}</p>
                            </div>
                        </div>
                        <div class="progress1__block__in__center">
                            <p class="progress1__block__in__center__status" :class="{customText: item.status != 'Завершено'}">{{ item.dateStatus }}</p>
                            <p class="progress1__block__in__center__date" :class="{customText: item.status != 'Завершено'}">{{ item.date }}</p>
                        </div>
                        <div class="progress1__block__in__right">
                            <button :class="{orangeButton: item.status == 'В процессе', greyButton: item.status == 'Заполнить форму'}">
                                <img src="@/assets/icons/galochkaWhite.svg" v-if="item.status == 'Завершено'"/>
                                <img src="@/assets/icons/clock.svg" v-if="item.status == 'В процессе'"/>
                                <img src="@/assets/icons/form.svg" v-if="item.status == 'Заполнить форму'"/>
                                <p>{{ item.status }}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="status">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>Статус вашей заявки</p>
                    <div class="line"></div>
                </div>
                <div class="status__block">
                    <img src="@/assets/icons/clock.svg"/>
                    <p class="status__block__title">Ждем ответа от ВУЗа</p>
                    <!-- <p class="status__block__text">Мы отправили Вашу заявку на поступление. Ваш куратор свяжется с Вами как только мы получим ответ</p> -->
                </div>
            </div>
            <div class="upload">
                <div class="lineTitle">
                    <div class="line"></div>
                    <p>ЗАГРУЗИТЕ ВАШ ОФФЕР</p>
                    <div class="line"></div>
                </div>
                <div class="upload__block">
                    <label class="upload__block__top" for="document">
                        <img src="@/assets/icons/plusGrey.svg"/>
                        <p>Загрузить файл</p>
                        <input type="file" id="document" style="display: none;"/>
                    </label>
                    <p class="upload__block__conditional">Вид оффера: <span>Conditional</span></p>
                    <!-- <p class="upload__block__text">Мы отправили Вашу заявку на поступление. Ваш куратор свяжется с Вами как только мы получим ответ</p> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo
    },
    data() {
        return {
            progress: [
                {
                  img: 'img1',
                  name: 'Резюме / CV',
                  text: 'Подготовка резюме для поступления',
                  dateStatus: 'Дата начала и завершения',
                  date: '08.11.2024 - 09.11.2024',
                  status: 'Завершено'
                },
                {
                  img: 'img2',
                  name: 'Академ. рекомендательное письмо',
                  text: 'Подготовка Вашего письма',
                  dateStatus: 'Дата начала и завершения',
                  date: '08.11.2024 - 09.11.2024',
                  status: 'Завершено'
                },
                {
                  img: 'img3',
                  name: 'Проф. рекомендательное письмо',
                  text: 'Подготовка Вашего проф. письма',
                  dateStatus: 'Дата начала и завершения',
                  date: '08.11.2024 - 09.11.2024',
                  status: 'Завершено'
                },
                {
                  img: 'img4',
                  name: 'Мотивационное письмо',
                  text: 'Подготовка Вашего мотив. письма',
                  dateStatus: 'Дата начала и завершения',
                  date: '12.11.2024',
                  status: 'В процессе'
                },
                {
                  img: 'img5',
                  name: 'Услуга',
                  text: 'Описание услуги',
                  dateStatus: 'Дата начала и завершения',
                  date: '08.11.2024 - 09.11.2024',
                  status: 'В процессе'
                },
                {
                  img: 'img6',
                  name: 'Application form',
                  text: 'Описание формы',
                  dateStatus: 'Дата начала и завершения',
                  date: '08.11.2024 - 09.11.2024',
                  status: 'Заполнить форму'
                },
            ]
        };
    },
};
</script>
<style lang="scss" scoped>
.lineTitle{ 
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    color: #0000008A;
    .line{
        width: 35%;
    }
    font-size: 24px;
}
.orangeButton{
    background: inherit !important;
    color: #FF8800 !important;
    border: 1px solid #FF8800 !important;
}
.greyButton{
    background: inherit !important;
    color: #0000004D !important;
    border: 1px solid #0000004D !important;
}
.customText{
    color: black;
}
.line {
    border-top: 1px solid #0000001A;
    width: 100%;
}
.cardBack{
    background: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 25px;
    &__bot{
        display: none;  
        p{
            color: black;
            text-align: center;
            font-size: 24px;
        }
    }
}
.card{
    width: 100%;
    aspect-ratio: 1.6;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-image: url('@/assets/icons/test.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    color: white;
    &__top{
        display: flex;
        justify-content: space-between;
        &__left{
            display: flex;
            gap: 20px;
            align-items: center;
            &__rank{
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;
                img{
                    width: 20px;
                }
            }
            &__text{
                display: flex;
                flex-direction: column;
                &__sub{
                    font-size: 24px;
                    font-weight: 700;
                }
            }
        }
        &__right{
            display: none;
            padding: 6px 12px;
            background-color: #FD2D2A;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .line3{
                border-top: 1px solid #FFFFFF8A;
                width: 100%;
            }
            height: fit-content;
            span{
                color: #FFFFFF8A;
            }
        }
    }
    &__bot{
        font-size: 32px;
        font-weight: 700;
    }
}
.admission {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                gap: 30px;
                align-items: center;
                font-size: 36px;
                font-weight: 600;
            }
            &__right {
                width: 25%;
            }
        }
        &__swiper{
            width: 100%;
            .swiper-slide-active .cardBack__bot{
                display: block !important;
            }
            .swiper-slide-active .card__top__right  {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
            &__slide{
                width: 100%;
                height: 100%;
            }
        }
    }
}
.progress1{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__block{
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__in{
            display: flex;
            justify-content: space-between;
            width: 100%;
            &__left{
                width: 35%;
                display: flex;
                gap: 20px;
                align-items: center;
                color: #0000004D;
                &__img{
                    border: 1px solid #0000004D;
                    padding: 15px;
                    border-radius: 12px;
                }
                &__info{
                    &__name{
                        font-size: 24px;
                        font-weight: 700;
                    }
                }
                img{
                    width: 40px;
                }
            }
            &__center{
                width: 20%;
                display: flex;
                flex-direction: column;
                color: #0000004D;
                font-size: 18px;
                &__date{
                    font-weight: 700;
                }
            }
            &__right{
                width: 20%;
                button{
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    padding: 15px 0px;
                    background-color: #14D448;
                    color: white;
                    border-radius: 10px;
                    img{
                        width: 30px;
                    }
                }
            }
        }
    }
}
.status{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    &__block{
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        &__title{
            font-size: 24px;
            font-weight: 700;
            color: #FF8800;
        }
        &__text{
        }
    }
    img{
        width: 20%;
    }
}
.upload{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    width: 100%;
    &__block{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        &__conditional{
            font-size: 20px;
            span{
                font-weight: 600;
            }
        }
        &__text{
            color: #0000008A;
        }
        &__top{
            width: 20%;
            padding: 40px 0px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            border: 1px solid #0000001A;
            background-color: #F0F2F5;
            border-radius: 25px;
            cursor: pointer;
            img{
                width: 50px;
            }
        }
    }
}
</style>