<template>
    <NewProgram v-if="openNewProgram" @closeModal="openNewProgram = false"/>
    <div class="programs">
        <div class="programs__left">
            <platformTable />
        </div>
        <div class="programs__right">
            <div class="programs__right__top">
                <div class="programs__right__top__left">
                    <p class="programs__right__top__left__title">Программы</p>
                </div>
                <div class="programs__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="programs__right__filter">
                <div class="programs__right__filter__search">
                    <input type="search" placeholder="Поиск программ по ID...." style="font-style: italic;"/>
                    <img src="@/assets/icons/find.svg"/>
                </div>
                <div class="programs__right__filter__select">
                    
                    <customSelect />
                </div>
                <div class="programs__right__filter__plus" @click="openNewProgram = true">
                    <img src="@/assets/icons/plus.svg"/>
                    <p>Добавить программу</p>
                </div>
            </div>
            <div class="programs__right__block">
                <div class="programs__right__block__in" v-for="item in 5">
                    <NewCardComponent/> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import NewProgram from "@/components/newPlatform/newProgram.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        NewCardComponent,
        NewProgram ,
        customSelect
    },
    data() {
        return {
            openNewProgram: false
        };
    },
};
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.programs {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                gap: 30px;
                align-items: center;
                font-size: 36px;
                &__title{
                    font-weight: 600;
                }
            }
            &__right {
                width: 25%;
            }
        }
        &__filter{
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            img{
                width: 20px;
                height: 20px;
            }
            input, select, &__plus{
                width: 100%;
                padding: 15px 25px;
                background-color: transparent;
                border: 1px solid #0000001A;
                box-shadow: 0px 0px 4px 0px #0000000D;
                border-radius: 10px;
                height: 100%;
            }
            &__search{
                width: 60%;
                position: relative;
                img{
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    right: 10px;
                    cursor: pointer;
                }
            }
            &__select{
                width: 15%;
                select{
                    padding: 15px 5px;
                }
            }
            &__plus{
                width: 23%;
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 25px;
            &__in{
                width: 49%;
            }
        }
    }
}
</style>