<template>
    <div class="table1">
        <div class="table1__top">
            <img src="@/assets/logo.svg"/>
            <div class="table1__top__right">
                AI TECH
            </div>
        </div>
        <div class="table1__block">
            <div class="table1__block__title">
                <p>Основное</p>
                <div class="line"></div>
            </div>
            <div class="table1__block__in" v-for="item in tabs" :key="item" @click="$router.push(`${item.route}`)" :class="{customTab : $route.path == item.route || $route.path == item?.route1}" v-if="$store.state.role == 'user'">
                <img src="@/assets/icons/fullHeart.svg" v-if="$route.path == '/testFavourite' && $route.path == item.route"/>
                <img :src="require('@/assets/icons/' + item.img + '.svg')" v-else/>
                <p>{{ item.name }}</p>
            </div>
            <div class="table1__block__in" v-for="(item, index) in tabsCurator" :key="index" @click="$router.push(`${item.route}`)" :class="{customTabCurator : $route.path == item.route || $route.path == item?.route1}" v-else>
                <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                <p>{{ item.name }}</p>
            </div>
        </div>
        <div class="table1__news" v-if="$store.state.role == 'user'">
            <div class="table1__news__title">
                <p>Новости</p>
                <div class="line"></div>
            </div>
            <div class="table1__news__in" v-for="item in news" :key="item">
                <img src="@/assets/icons/test.png"/>
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isCurator: true,
            tabsCurator: [
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/curator'
                },
                {
                    img: 'tabc_2',
                    name: 'Программы',
                    route: '/curator/programs',
                },
                {
                    img: 'tabc_3',
                    name: 'Пользователи',
                    route: '/curator/users'
                },
                {
                    img: 'tabc_4',
                    name: 'Уведомления',
                    route: '/curator/notifications'
                },
                {
                    img: 'tab_8',
                    name: 'Выйти'
                },
            ],
            tabs:[
                {
                    img: 'tab_1',
                    name: 'Главная страница',
                    route: '/testPlatform'
                },
                {
                    img: 'tab_2',
                    name: 'Поиск программ',
                    route: '/testPlatformFind',
                    route1: '/testPlatformFind/1',
                },
                {
                    img: 'tab_3',
                    name: 'Избранное',
                    route: '/testFavourite'
                },
                {
                    img: 'tab_4',
                    name: 'Поступление',
                    route: '/testPlatform/admission'
                },
                {
                    img: 'tab_5',
                    name: 'Международные гранты',
                    route: '/testPlatform/intGrants'
                },
                {
                    img: 'tab_6',
                    name: 'Языковая и тест. школа',
                    route: '/testPlatform/schools'
                },
                {
                    img: 'tab_9',
                    name: 'Премиум опции',
                    route: '/testPlatform/premium'
                },
                {
                    img: 'tab_7',
                    name: 'Полезное',
                    route: '/testPlatform/useful'
                },
                {
                    img: 'tab_8',
                    name: 'Выйти'
                },
            ],
            news: [
                {
                    name: 'Добавлено 120 новых учреждений'
                },
                {
                    name: 'Добавлено 5 новых занятий'
                },
                {
                    name: 'Добавлено 5 новых занятий'
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #FFFFFF1A;
    height: 1px;
    width: 100%;
}
.customTab{
    background: white;
    border-radius: 5px;
    color: #11415D !important   ;
    img{
        filter: brightness(0) saturate(100%) invert(18%) sepia(12%) saturate(7315%) hue-rotate(175deg) brightness(90%) contrast(87%);
    }
}
.customTabCurator{
    background: white;
    border-radius: 5px;
    width: 100%;
    color: #FD2D2A !important;
    img{
        filter: brightness(0) saturate(100%) invert(43%) sepia(76%) saturate(3165%) hue-rotate(336deg) brightness(90%) contrast(122%) !important; 
    }
}
    .table1{
        margin: 0px !important;
        padding: 10px 30px;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            img{
                width: 60%;
            }
            &__right{
                width: 36%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                font-size: 20px;
                font-weight: 700;
                border-radius: 5px;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: 18px;
            width: 100%;
            &__title{
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FFFFFF8A;
            }
            &__in{
                display: flex;
                width: 100%;
                align-items: center;
                padding: 15px;
                gap: 15px;
                color: white;
                cursor: pointer;
                img{
                    width: 20px;
                    object-fit: cover;
                }
            }
        }
        &__news{
            display: flex;
            flex-direction: column;
            gap: 20px;
            &__title{
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FFFFFF8A;
                font-size: 18px;
            }
            &__in{
                width: 100%;
                aspect-ratio: 1.3;
                background-size: cover;
                background-color: #FFFFFF4D;
                border-radius: 8px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                padding: 10px;
                img{
                    width: 100%;
                    aspect-ratio: 1.78;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }
    }
</style>