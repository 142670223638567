<template>
    <div class="users">
        <div class="users__left">
            <platformTable />
        </div>
        <div class="users__right">
            <div class="users__right__top">
                <div class="users__right__top__left" v-if="isInAdmission">
                    <p>Пользователи > Максим М. > <span>Поступление ВУЗ</span></p>
                </div>
                <div class="users__right__top__left" v-else>
                    <p style="color: #0000008A;">Пользователи ></p>
                    <p class="users__right__top__left__title">Максим М.</p>
                </div>
                <div class="users__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="userCard">
                <div class="userCard__customTop" v-if="isInAdmission">
                    <div class="userCard__customTop__left">
                        <img src="@/assets/icons/univer.svg"/>
                        <div class="userCard__customTop__left__right">
                            <p class="userCard__customTop__left__right__name bold">Massachusetts Institute of Technology</p>
                            <p class="userCard__customTop__left__right__email grey">Massachusets, USA</p>
                        </div>
                    </div>
                    <div class="userCard__customTop__export">
                        <img src="@/assets/icons/export.svg"/>
                        <p>Экспортировать данные</p>
                    </div>
                </div>
                <div class="userCard__top" v-else>
                    <img src="@/assets/icons/max.svg"/>
                    <div class="userCard__top__right">
                        <p class="userCard__top__right__name bold">Максим Максимов</p>
                        <p class="userCard__top__right__email grey">id</p>
                    </div>
                </div> 
                <div class="userCard__tabs">
                    <div class="userCard__tabs__in" v-for="(item,index) in tabs" :key="item" @click="activeTab = index">
                        <div class="userCard__tabs__in__name" :class="{activeTab: index == activeTab}">{{item.name}}</div>
                        <div class="line2"></div>
                    </div>
                </div>
                <div class="main" v-if="activeTab == 0 && isInAdmission == false">
                    <div class="customTitle">
                        <p class="customTitle__left">Договор</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__add">
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs">
                        <div class="customInput">
                            <label>Статус договора</label>
                            <customSelect/>
                        </div>
                        <div class="customInput">
                            <label>Номер договора</label>
                            <input placeholder="Не выбрано"/>
                        </div>
                        <div class="customInput">
                            <label>Дата заключения договора</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Количество дней договора</label>
                            <input placeholder=".."/>
                        </div>
                        <div class="customInput">
                            <label>Подача</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Статус клиента</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Желаемая степень</label>
                            
                    <customSelect />
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Виджет “Ключевые сроки” > GENERAL ENGLISH</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__add">
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="ELEMENTARY"/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                        <div class="main__inputs__delete">
                            <img src="@/assets/icons/delete.svg"/>
                        </div>
                        <div class="main__inputs__select">
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="PRE-INTERMEDIATE"/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                        <div class="main__inputs__delete">
                            <img src="@/assets/icons/delete.svg"/>
                        </div>
                        <div class="main__inputs__select">
                            
                    <customSelect />
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Виджет “Ключевые сроки” > Тесты</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__add">
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs1">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="ELEMENTARY"/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                        <div class="main__inputs__delete">
                            <img src="@/assets/icons/delete.svg"/>
                        </div>
                        <div class="main__inputs__select">
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="PRE-INTERMEDIATE"/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                        <div class="main__inputs__delete">
                            <img src="@/assets/icons/delete.svg"/>
                        </div>
                        <div class="main__inputs__select">
                          
                    <customSelect />
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Виджет “Индивидуальный план”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInput">
                            <label>Индивидуальный план 1</label>
                            <input type="text" placeholder="hyperlink"/>
                        </div>
                        <div class="customInput">
                            <label>Индивидуальный план 2</label>
                            <input type="text" placeholder="hyperlink"/>
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Виджет “Международная стипендия” </p>
                    </div>
                    <div class="main__inputs1">
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="..."/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Название элемента</label>
                            <input placeholder="..."/>
                        </div>
                        <div class="customInput">
                            <label>Срок</label>
                            <input type="date"/>
                        </div>
                    </div><div class="customTitle">
                        <p class="customTitle__left">Виджет “Риски”</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInput">
                            <label>Риски пользователя</label>
                            
                    <customSelect />
                        </div>
                    </div>
                    <div class="main__risks">
                        <div class="main__risks__in" v-for="item in 3">
                            <img src="@/assets/icons/exit.svg"/>
                            <p>Название риска</p>
                        </div>
                    </div>
                    <div class="main__risk">
                        <input placeholder="Введите название риска..."/>
                        <button>Добавить новый риск</button>
                    </div>  
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 1 && isInAdmission == false">
                    <AddProduct v-if="openAdd" @closeModal="openAdd = false" :is-add="true"/>
                    <AddProduct v-if="openRedactor" @closeModal="openRedactor = false" :is-redactor="true"/>
                    <div class="customTitle">
                        <p class="customTitle__left">Личная информация</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__add">
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="general__inputs">
                        <div class="customInput">
                            <label>Фамилия</label>
                            <input placeholder="Жексебайулы"/>
                        </div>
                        <div class="customInput">
                            <label>Имя</label>
                            <input placeholder="Жексебайулы"/>
                        </div>
                        <div class="newCustomInput">
                            <label>ИИН</label>
                            <div class="newCustomInput__in">
                                <input placeholder="Жексебайулы" />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="customInput">
                            <label>Дата рождения</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Страна</label>
                          
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Город</label>
                          
                    <customSelect />
                        </div>
                        <div class="newCustomInput">
                            <label>Телефон</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Почта</label>
                            <div class="newCustomInput__in">
                                <input placeholder="max.max@gmail.co..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Телеграм</label>
                            <div class="newCustomInput__in">
                                <input placeholder="max.max@gmail.co..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div> 
                        <div class="customInput">
                            <label>Пол</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Адрес</label>
                            <input placeholder="Адрес"/>
                        </div>
                        <div class="customInput">
                            <label>Id</label>
                            <input placeholder="9778"/>
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Ваши покупки</p>
                    </div>
                    <div class="general__buys">
                        <div class="general__buys__in" v-for="item in 2" :key="item">
                            <div class="general__buys__in__edit">
                                <img src="@/assets/icons/redactor.svg" @click="openRedactor = true"/>
                                <div class="line1"></div>
                                <img src="@/assets/icons/basketGrey.svg"/>
                            </div>
                            <p class="general__buys__in__title">НАСТАВН.</p>
                            <div class="line"></div>
                            <img src="@/assets/icons/test.png"/>
                            <p class="general__buys__in__name">BOLASHAK</p>
                            <p class="general__buys__in__sub">3 ВУЗа</p>
                        </div>
                        <div class="general__buys__plus" @click="openAdd = true">
                            <img src="@/assets/icons/plus.svg"/>
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Прогресс по клиенту</p>
                    </div>
                    <div class="general__progress">
                        <div class="general__progress__in">
                            <div class="customInput">
                                <label>Введите процент</label>
                                <input />
                            </div>
                            <button>Применить</button>
                        </div><div class="general__progress__in">
                            <div class="customInput">
                                <label>Выберите этап</label>
                               
                    <customSelect />
                            </div>
                            <button>Применить</button>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="document" v-if="activeTab == 3 && isInAdmission == false">
                    <AddFile v-if="openFile" @closeModal="openFile = false"/>
                    <p class="document__title">Файлы и документы</p>
                    <div class="document__block">
                        <div class="document__block__in" v-for="item in documents" :key="item">
                                <img class="document__block__in__delete" src="@/assets/icons/delete.svg"/>
                            <div class="document__block__in__left">
                                <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                            </div>
                            <div class="line3"></div>
                            <div class="document__block__in__right">
                                <p class="document__block__in__right__pdf" :class="{approved: item.type == 'approved', notApproved: item.type == 'not'}">Link-here</p>
                                <p class="document__block__in__right__name" :class="{approved: item.type == 'approved', notApproved: item.type == 'not'}">{{ item.name }}</p>
                                <div class="document__block__in__right__undefined" v-if="item.type == 'undefined'">
                                    <p class="document__block__in__right__undefined__left">Approve</p>
                                    <div class="line3"></div>
                                    <p class="document__block__in__right__undefined__right">Not yet</p>
                                </div>
                                <div class="document__block__in__right__approved" v-if="item.type == 'approved'">Approved</div>
                                <div class="document__block__in__right__not" v-if="item.type == 'not'">Not yet</div>
                            </div>
                        </div>
                        <div class="document__block__plus" @click="openFile = true">
                            <img src="@/assets/icons/plus.svg"/>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="favourite" v-if="activeTab == 4 && isInAdmission == false">
                    <div class="customTitle">
                        <p class="customTitle__left">Избранные программы клиента</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__add" @click="sendSuper">
                                <p>Отправить супервайзеру</p>
                            </div>
                        </div>
                    </div>
                    <div class="favourite__block">
                        <div class="favourite__block__in" v-for="item in 6" :key="item">
                            <NewCardComponent />
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="admission" v-if="activeTab == 5 && isInAdmission == false">
                    <div class="customTitle">
                        <p class="customTitle__left">Выберите ВУЗ для детального редактирования</p>
                    </div>
                    <div class="admission__block">
                        <div class="admission__block__in" v-for="item in 6" :key="item">
                            <NewCardComponent  :is-admission="true" @click="isInAdmission = true"/>
                        </div>
                    </div>
                </div>
                <div class="general" v-if="activeTab == 2 && isInAdmission == false">
                    <div class="customTitle">
                        <p class="customTitle__left">Академические результаты</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__delete">
                                <img src="@/assets/icons/delete.svg"/>
                                <p>Удалить поле</p>
                            </div>
                            <div class="customTitle__right__add" >
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="general__inputs">
                        <div class="newCustomInput">
                            <label>GPA бакалавр</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>GPA магистратура</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Placement Test Level</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Placement Test Date</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..."/>
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>IELTS/TOEFL</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..."/>
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>IELTS/TOEFL Date</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..."/>
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>KazTest/Qazresmi</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>KazTest/Qazresmi Date</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>GRE/GMAT</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div> 
                        <div class="newCustomInput">
                            <label>GRE/GMAT Date</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Название данных</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                        <div class="newCustomInput">
                            <label>Название данных</label>
                            <div class="newCustomInput__in">
                                <input placeholder="..." />
                                <div class="line1"></div>
                                <p>Подтвердить</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
                <div class="inAdmission" v-if="isInAdmission">
                    <div class="customTitle">
                        <p class="customTitle__left">Договор</p>
                    </div>
                    <div class="main__inputs">
                        <div class="customInput">
                            <label>Поступление</label>
                          
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Приоритет</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Статус заявки</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Вид оффера</label>
                           
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Deadline 1</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Deadline 2</label>
                            <input type="date"/>
                        </div>
                    </div>
                    <div class="customTitle">
                        <p class="customTitle__left">Сроки поступления и услуг</p>
                        <div class="customTitle__right">
                            <div class="customTitle__right__delete">
                                <img src="@/assets/icons/delete.svg"/>
                                <p>Удалить поле</p>
                            </div>
                            <div class="customTitle__right__add">
                                <img src="@/assets/icons/plus.svg"/>
                                <p>Добавить поле</p>
                            </div>
                        </div>
                    </div>
                    <div class="main__inputs">
                        <div class="customInput">
                            <label>Вид элемента</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Дата начала</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Дата завершения</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Статус услуги</label>
                           
                    <customSelect />
                        </div>
                        <div class="line"></div>
                        <div class="customInput">
                            <label>Вид элемента</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Дата начала</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Дата завершения</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Статус услуги</label>
                           
                    <customSelect />
                        </div>
                        <div class="line"></div>
                        <div class="customInput">
                            <label>Вид элемента</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Дата начала</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Дата завершения</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Статус услуги</label>
                           
                    <customSelect />
                        </div>
                        <div class="line"></div>
                        <div class="customInput">
                            <label>Вид элемента</label>
                            
                    <customSelect />
                        </div>
                        <div class="customInput">
                            <label>Дата начала</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Дата завершения</label>
                            <input type="date"/>
                        </div>
                        <div class="customInput">
                            <label>Статус услуги</label>
                           
                    <customSelect />
                        </div>
                    </div>
                    <div class="main__buttons">
                        <button class="main__buttons__save">Применить</button>
                        <button class="main__buttons__delete">Сбросить</button>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import NewCardComponent from "@/components/newCardComponent.vue";
import AddProduct from "@/components/newPlatform/addProduct.vue";
import AddFile from "@/components/newPlatform/addFile.vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        NewCardComponent,
        AddProduct,
        AddFile,
        customSelect
    },
    data() {
        return {
            tabs: [
                {
                    name: 'Главное'
                },
                {
                    name: 'Общее'
                },
                {
                    name: 'Результаты'
                },
                {
                    name: 'Документы'
                },
                {
                    name: 'Избранное'
                },
                {
                    name: 'Поступление'
                },
            ],
            activeTab: 3,
            isInAdmission: false,
            openAdd: false,
            openRedactor: false,
            openFile: false,
            documents: [
            {
                img: 'doc1',
                name: 'Утвержден. программы',
                type: 'undefined'
            },
            {
                img: 'doc2',
                name: 'Резюме',
                type: 'approved'
            },
            {
                img: 'doc3',
                name: 'Реком. письмо 1',
                type: 'not'
            },
            {
                img: 'doc4',
                name: 'Утвержден. программы',
                type: 'undefined'
            },
        ],
        };
    },
    methods: {
        sendSuper(){
            toast.success("Отправлено");
        }
    }
};
</script>
<style lang="scss" scoped>
.customTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left{
        font-weight: 700;
        font-size: 24px;
    }
    &__right{
        display: flex;
        gap: 10px;
        align-items: stretch;
        &__delete, &__add{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
        }
        &__delete{
            border: 1px solid #FD2D2A;
            color: #FD2D2A;
        }
        &__add{
            border: 1px solid #0000001A;
        }
        img{
            width: 20px;
            height: 20px;
        }
    }
}
.newCustomInput{
  position: relative;
  display: inline-block;
  width: 32%;
  cursor: pointer;
  label{
    position: absolute;
    top: -8px;
    left: 25px;
    background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: 14px;
    color: #000000; 
  }
  &__in{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 25px;
    height: 50px;
    border: 1px solid #e0e0e0; /* Цвет рамки */
    border-radius: 8px;
    font-size: 20px;
    background-color: inherit;
    color: #000; /* Цвет текста */
    outline: none;
    align-items: center;
    input, select{
        width: 50%;
        border: none;
    }
  }
}
.line1{
    height: 30px;
    border-left: 1px solid #0000001A;
}
.customInput{
  position: relative;
  display: inline-block;
  width: 23.5%;
  cursor: pointer;
  label{
    position: absolute;
    top: -8px;
    left: 25px;
    background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: 14px;
    color: #c0c0c0; 
    z-index: 98;
  }
  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input {
    width: 100%;
    padding: 0 25px;
    border: 1px solid  #0000001A;;
    height: 50px;
    border-radius: 8px;
    font-size: 20px;
    background-color: inherit;
    color: #000; /* Цвет текста */
    outline: none;
    img{
        width: 16px;
        height: 20px;
    }
  }
}
.activeTab{
        border-bottom: 1px solid red !important;
        color: black !important;
        font-weight: 600 !important;
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
.line3{
    border-left: 1px solid #0000001A;
}
.bold{
    font-weight: 600;
}
.grey{
    color: #0000008A;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.users {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 36px;
                span{
                    color: black !important;
                    font-weight: 600;
                }
                &__title{
                    font-weight: 600;
                }
            }
            &__right {
                width: 25%;
            }
        }
    }
}
.userCard{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 25px;
    background-color: white;
    border-radius: 25px;
    &__customTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__left{
            display: flex;
            gap: 25px;
            align-items: center;
            &__right{
            display: flex;
            flex-direction: column;
            gap: 5px;
            &__name{
                font-size: 32px;
            }
        }
        img{
            width: 100px;
            aspect-ratio: 1;
        }
        }
        &__export{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 15px;
            color: #0000008A;
            font-size: 20px;
            border: 1px solid #0000001A;
            border-radius: 10px;
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    &__top{
        display: flex;
        gap: 25px;
        align-items: center;
        &__right{
            display: flex;
            flex-direction: column;
            gap: 5px;
            &__name{
                font-size: 32px;
            }
        }
        img{
            width: 100px;
            aspect-ratio: 1;
        }
    }
    &__tabs{
        display: flex;
        align-items: flex-end;
        &__in{
            .line2{
                border-bottom: 1px solid #0000001A;
                width: 30px;
            }
            &__name{
                padding-bottom: 5px;
                border-bottom:  1px solid #0000001A;
                color: #0000004D;
            }
            cursor: pointer;
            display: flex;
            align-items: flex-end;
        }
    }
    .main{
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__inputs, &__inputs1{
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: 25px;
            &__delete{
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                aspect-ratio: 1;
                border: 1px solid #FD2D2A;
                border-radius: 10px;
                transition: all 0.5s ease;
                &:hover{
                    background-color: #FD2D2A;
                    img{
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(11%) hue-rotate(189deg) brightness(103%) contrast(105%);
                    }
                }
                img{
                    width: 15px;
                }
            }
            &__select{
                width: 15%;
                select{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        &__inputs1{
            gap: 2%;
            row-gap: 25px;
            .customInput{
                width: 35%;
            }
        }
        &__risk{
            display: flex;
            gap: 2%;
            input, button{
                width: 23.5%;
                height: 50px;
                padding: 15px 25px;
                border: 1px solid #e0e0e0; /* Цвет рамки */
                border-radius: 8px;
                font-style: italic;;
            }
            button{
                border: 2px solid #11415D;
                font-style: normal;
                padding: 0px;
                background-color: inherit;
                color: #11415D;
            }
        }
        &__risks{
            display: flex;
            gap: 20px;
            align-items: stretch;
            &__in{
                display: flex;
                gap: 10px;
                padding: 10px;
                align-items: center;
                color: white;
                background-color: #FD2D2A;
                cursor: pointer;
                border-radius: 10px;
                img{
                    width: 15px;
                    height: 15px;
                }
            }
        }
        &__buttons{
            display: flex;
            gap: 2%;
            button{
                width: 23.5%;
                border: none;
                height: 50px;
                border-radius: 10px;
            }
            &__save{
                color: white;
                font-size: 20px;
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #2076a7;
                }
            }
            &__delete{
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: 20px;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }
    .general{
        display: flex;
        flex-direction: column;
        gap: 30px;
        .customInput{
            width: 32%;
        }
        &__inputs{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 25px;
        }
        &__buys{
            display: flex;
            gap: 2%;
            &__in, &__plus{
                position: relative;
                width: 23.5%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                justify-content: center;
                padding: 15px 30px;
                border: 1px solid #0000001A;
                &__edit{
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    display: flex;
                    gap: 10px;
                    padding: 10px;
                    background-color: #F8F8F8;
                    border-radius: 8px;
                    align-items: center;
                    .line1{
                        height: 10px;
                    }
                    img{
                        width: 15px !important;
                        height: 15px !important;
                        cursor: pointer;
                    }
                }
                &__title{
                    color: #0000008A;
                }
                &__name{
                    font-size: 24px;
                    font-weight: bold;
                }
                &__sub{
                    color: #FD2D2A;
                    font-weight: bold;
                }
                img{
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                }
            }
            &__plus{
                background-color: #F8F8F8;
                box-shadow: 0px 0px 8px 4px #0000000D inset;
            }
        }
        &__progress{
            display: flex;
            flex-direction: column;
            gap: 30px;
            &__in{
                display: flex;
                gap: 20px;
                button{
                    width: 23.5%;
                    border: none;
                    border-radius: 10px;
                    background-color: #14D448;
                    color: white;
                }
            }
        }
    }
    .document{
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__title{
            font-size: 24px;
            font-weight: 700;
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 25px;
            &__in{
                width: 23.5%;
                padding: 15px 25px;
                display: flex;
                justify-content: space-between;
                box-shadow: 0px 4px 3px 1px #0000000D;
                background: #FFFFFF;
                border-radius: 10px;
                position: relative;
                &__delete{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
                &__left{
                    width: 15%;
                    img{
                        width: 100%;
                    }
                }
                &__right{
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    &__name{
                        font-weight: 700;
                        flex: 1;
                    }
                    &__undefined{
                        display: flex;
                        justify-content: space-between;
                        padding: 10px 20px; 
                        border: 1px solid #0000001A;
                        border-radius: 10px;
                        cursor: pointer;
                        &__left{
                            color: #00D928;
                        }
                        &__right{
                            color: #FF9500;
                        }
                    }
                    &__approved{
                        padding: 10px 20px;
                        background-color: #F8F8F8;
                        width: fit-content;
                        border-radius: 10px;
                        color: #0000008A;
                        cursor: pointer;
                    }
                    &__not{
                        padding: 10px 20px;
                        background-color: #FF9500;
                        width: fit-content;
                        color: white;
                        border-radius: 10px;
                        cursor: pointer;
                    }
                }
            }
            &__plus{
                width: 23.5%;
                padding: 30px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 8px 4px #0000000D inset;
                border: 1px solid #0000001A;
                border-radius: 10px;
                cursor: pointer;
                img{
                    width: 60px;
                    height: 60px;
                }
            }
        }
        &__info{
            display: flex;
            gap: 20px;
            align-items: center;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    .favourite{
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 30px;
            &__in{
                width: 49%;
            }
        }
    }
    .admission{
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 30px;
            &__in{
                width: 49%;
            }
        }
    }
    .inAdmission{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}
</style>