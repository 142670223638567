<template>
    <div class="users">
        <div class="users__left">
            <platformTable />
        </div>
        <div class="users__right">
            <div class="users__right__top">
                <div class="users__right__top__left">
                    <p class="users__right__top__left__title">Пользователи</p>
                    <p style="color: #0000008A;">80 человек</p>
                </div>
                <div class="users__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="users__right__table">
                <div class="users__right__table__top">
                    <div class="users__right__table__top__left">
                        <img src="@/assets/icons/achieveIcon.svg"/>
                        <p>Список клиентов</p>
                    </div>
                    <div class="users__right__table__top__right">
                        <div class="users__right__table__top__right__search">
                            <input type="search" placeholder="Поиск по ФИО"/>
                            <img src="@/assets/icons/find.svg"/>
                        </div>
                        <div class="users__right__table__top__right__export">
                            <p>Экспортировать данные</p>
                            <img src="@/assets/icons/export.svg"/>
                        </div>
                        <div class="users__right__table__top__right__filter" @click="openFilter = !openFilter" :class="{customFilter: openFilter}">
                            <p>Фильтр</p>
                            <img src="@/assets/icons/filter.svg"/>
                        </div>
                        <div class="users__right__table__top__right__select">
                            <p>Сортировка:</p>
                            
                    <customSelect :is-border="true" />
                        </div>
                    </div>
                </div>
                <div class="selects" v-if="openFilter">
                    <div class="selects__top">
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                        <div class="selects__top__in">
                            <customSelect />
                        </div>
                    </div>  
                    <div class="selects__bot">
                        <button class="selects__bot__save">Применить</button>
                        <button class="selects__bot__delete">Сбросить</button>
                    </div>
                </div>
                <div class="customTable">
                    <div class="customTable__left">
                        <div class="customTable__left__top">
                            <input type="checkbox" /> 
                            <p>Имя пользователя</p>
                        </div>
                        <div class="customTable__left__bot" v-for="item in 6">
                            <input type="checkbox" /> 
                            <div class="customTable__left__bot__person" @click="$router.push('/curator/users/1')">
                                <img src="@/assets/icons/person.svg"/>
                                <div class="customTable__left__bot__person__info">
                                    <p class="customTable__left__bot__person__info__id">ID: 896879</p>
                                    <p class="customTable__left__bot__person__info__name">Анна Дублина</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 1">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__status">Статус клиента</p>
                            <p class="customTable__right__top__progress">Прогресс бар</p>
                            <p class="customTable__right__top__et1">Этап</p>
                            <p class="customTable__right__top__ielts">IELTS</p>
                            <p class="customTable__right__top__risk">С рисками</p>
                            <img src="@/assets/icons/blackSelect.svg" @click="opentable = 2" />
                        </div>
                        <div class="customTable__right__bot" v-for="item in 6">
                            <div class="customTable__right__bot__status">Истек</div>
                            <div class="customTable__right__bot__progress">%</div>
                            <div class="customTable__right__bot__et1">Значение</div>
                            <div class="customTable__right__bot__ielts">Значение</div>
                            <div class="customTable__right__bot__risk">Значение</div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                    <div class="customTable__right" v-if="opentable == 2">
                        <div class="customTable__right__top">
                            <p class="customTable__right__top__year">Год поступления</p>
                            <p class="customTable__right__top__packet">Пакет</p>
                            <p class="customTable__right__top__curator">Куратор</p>
                            <img src="@/assets/icons/blackSelect.svg" style="rotate: 180deg;" @click="opentable = 1"/>
                        </div>
                        <div class="customTable__right__bot" v-for="item in 6">
                            <div class="customTable__right__bot__year">Значение</div>
                            <div class="customTable__right__bot__packet">Значение</div>
                            <div class="customTable__right__bot__curator">Значение</div>
                            <div class="customTable__right__bot__img"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import customSelect from "@/components/customSelect.vue";
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        customSelect
    },
    data() {
        return {
            openFilter: false,
            opentable: 1
        };
    },
};
</script>
<style lang="scss" scoped>
.customTable{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left{
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        input{
            width: 7%;
            aspect-ratio: 1;
            cursor: pointer;
        }
        &__top{
            padding: 15px;
            height: fit-content;
            background-color: #F8F8F8;
            display: flex;
            justify-content: space-between;
            p{
                width: 90%;
                text-align: center;
                color: #0000008A;
            }
        }
        &__bot{
            display: flex;
            padding: 10px;
            height: 70px;
            &__person{
                width: 90%;
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;
                img{
                    width: 50px;
                    aspect-ratio: 1;
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    color: #FD2D2A;
                    &__name{
                        font-weight: 700;
                    }
                }
            }
        }
    }
    &__right{
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        img{
            position: absolute;
            right: 10px;
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
        &__top{
            padding: 15px;
            height: fit-content;
            background-color: #F8F8F8;
            display: flex;
            align-items: center;
            gap: 2%;
            position: relative;
            p{
                width: 18%;
                text-align: center;
                color: #0000008A;
            }
        }
        &__bot{
            display: flex;
            padding: 15px;
            align-items: center;
            height: 70px;
            gap: 2%;
            position: relative;
            &__status{
                color: #FD2D2A;
                font-weight: 600;
            }
            &__progress{
                color: #FD2D2A;
                font-weight: 600;
            }
            &__img{
                position: absolute;
                width: 10px !important;
                height: 10px !important; 
            }
            div{
                width: 18%;
                text-align: center;
            }
        }
    }
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}
.customFilter{
    background: #11415D;
    p{
        color: white !important;
    }
    img{
        filter: brightness(0) saturate(100%) invert(99%) sepia(94%) saturate(3%) hue-rotate(179deg) brightness(104%) contrast(100%);
    }
}
.users {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                gap: 30px;
                align-items: center;
                font-size: 36px;
                &__title{
                    font-weight: 600;
                }
            }
            &__right {
                width: 25%;
            }
        }
        &__table{
            display: flex;
            background-color: #FFFFFF;
            flex-direction: column;
            gap: 25px;
            padding: 10px;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    width: 15%;
                    img{
                        width: 40px;
                    }
                }
                &__right{
                    display: flex;
                    gap: 20px;
                    width: 80%;
                    gap: 10px;
                    justify-content: flex-end;
                    align-items: stretch;
                    font-size: 14px;
                    img{
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }
                    &__select, &__export{
                        max-width: 35%;
                        width: fit-content;
                    }
                    &__search{
                        width: 20%;
                        border: 1px solid #0000001A;
                        padding: 10px;
                        border-radius: 10px;
                        position: relative;
                        cursor: pointer;
                        img{
                            position: absolute;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            right: 5px;
                        }
                        input{
                            border: none;
                            font-style: italic;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &__select, &__export, &__filter{
                        display: flex;
                        cursor: pointer;
                        gap: 5px;
                        border-radius: 10px;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #0000001A;
                        padding: 0px 10px;
                        p{
                            color: #0000008A;
                        }
                        select{
                            border: none;
                        }
                    }
                }
            }
            .selects{
                display: flex;
                flex-direction: column;
                gap:25px;
                width: 100%;
                &__top{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 3.5%;
                    row-gap: 30px;
                    &__in{
                        width: 31%;
                        cursor: pointer;
                    }
                }
                &__bot{
                    display: flex;
                    gap: 20px;
                    button{
                        width: 20%;
                        border: none;
                        padding: 12px 0px;
                        border-radius: 10px;
                    }
                    &__save{
                        color: white;
                        font-size: 20px;
                        background-color: #11415D;
                        transition: all 0.5s ease;
                            &:hover{
                                color: white;
                                background-color: #2076a7;
                            }
                    }
                    &__delete{
                        background-color: inherit;
                        font-size: 20px;
                        border: 1px solid #0000001A !important;
                        color: #0000008A;
                            transition: all 0.5s ease;
                            &:hover{
                                color: white;
                                background-color: #11415D;
                                border: 1px solid #11415D;
                            }
                    }
                }
            }
        }
    }
}
</style>