<template>
    <div class="curatorBack" @click="closeModal">
        <div class="curator" >
            <p class="curator__title">Feedback отправлен</p>
            <p class="curator__sub">Спасибо за Ваш отзыв о некорректной информации. Наша команда скоро проведет проверку</p>
            <div class="line"></div>
            <img src="@/assets/icons/ok.svg" class="curator__ok"/>
            <button class="curator__ready" @click="closeModal">Готово</button>
        </div> 
    </div>
</template>
<script>
export default {
    data(){
        return{
            isOk: false,
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
}
.curatorBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.curator{
    width: 33%;
    background-color: white;
    padding: 40px 60px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &__title{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #11415D;
    }
    &__sub{
        color: #0000008A;
        text-align: center;
    }
    &__ready{
        margin-top: 20px;
        background-color: #11415D;
        color: white;
        border: none;
    }
    button{
        width: 48%;
        padding: 15px 0px;
        border-radius: 10px;
    }
    &__ok{
        width: 40%;
    }
}
</style>